/* eslint no-console:off */
import { get, patch } from '../../utils'
import { API_AUTH, API_AUTH_UPDATE_PROFILE } from '../api-constraint'

export const CHECK_SESSION_REQUEST = 'CHECK_SESSION_REQUEST'
export const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS'
export const CHECK_SESSION_FAILURE = 'CHECK_SESSION_FAILURE'
export const CHECK_SESSION_IDLE = 'CHECK_SESSION_IDLE'

export const checkSessionRequest = () => {
  return {
    type: CHECK_SESSION_REQUEST,
  }
}

export const checkSessionSuccess = session => {
  return {
    type: CHECK_SESSION_SUCCESS,
    payload: {
      session,
    },
  }
}

export const checkSessionFailure = error => {
  return {
    type: CHECK_SESSION_FAILURE,
    payload: {
      error,
    },
  }
}

export const checkSessionIdle = () => {
  return {
    type: CHECK_SESSION_IDLE,
  }
}

export const checkSession = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(checkSessionRequest())
    try {
      const session = await get({
        path: API_AUTH,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(checkSessionSuccess(session))
    } catch (err) {
      console.log('checkSessionFailure')
      console.log(err)
      dispatch(checkSessionFailure(err))
    } finally {
      dispatch(checkSessionIdle())
    }
  }
}

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'
export const UPDATE_USER_PROFILE_IDLE = 'UPDATE_USER_PROFILE_IDLE'

export const updateUserProfileRequest = () => {
  return {
    type: UPDATE_USER_PROFILE_REQUEST,
  }
}

export const updateUserProfileSuccess = session => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: {
      session,
    },
  }
}

export const updateUserProfileFailure = error => {
  return {
    type: UPDATE_USER_PROFILE_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateUserProfileIdle = () => {
  return {
    type: UPDATE_USER_PROFILE_IDLE,
  }
}

export const updateUserProfile = data => {
  return async dispatch => {
    dispatch(updateUserProfileRequest())
    try {
      const session = await patch({
        path: `${API_AUTH_UPDATE_PROFILE}`,
        body: data,
      })
      dispatch(updateUserProfileSuccess(session))
    } catch (err) {
      dispatch(updateUserProfileFailure(err))
    } finally {
      dispatch(updateUserProfileIdle())
    }
  }
}
