/* eslint no-useless-escape: "off" */
import 'isomorphic-unfetch'
import crypto from 'crypto'
import getConfig from 'next/config'

const { publicRuntimeConfig = {} } = getConfig() || {}
const { SERVER_URL, HASH_SALT } = publicRuntimeConfig

const jsonHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.body
  }
  const error = new Error(response.statusText)
  error.response = response.body
  error.status = response.status
  throw error
}

export function parseJSON(response) {
  return response
    .json()
    .then(body => {
      return {
        status: response.status,
        statusText: response.statusText,
        body,
      }
    })
    .catch(() => {
      return response
    })
}

export function call({ path, method, body, headers, signal }) {
  const mockServerURL = 'http://localhost:4567'
  const serverURL = `${SERVER_URL || mockServerURL}${path}`
  return fetch(serverURL, {
    method,
    credentials: 'include',
    headers,
    signal,
    body: JSON.stringify(body),
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server',
        }
      }
      throw error
    })
}

const createJsonHeader = properties => {
  return { ...jsonHeader, ...properties }
}

export function get({ path, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({ path, method: 'GET', headers: updatedJsonHeader, signal })
}

export function post({ path, body, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({ path, method: 'POST', headers: updatedJsonHeader, body, signal })
}

export function put({ path, body, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({ path, method: 'PUT', headers: updatedJsonHeader, body, signal })
}

export function del({ path, body, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({ path, method: 'DELETE', headers: updatedJsonHeader, body, signal })
}

export function patch({ path, body, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({ path, method: 'PATCH', headers: updatedJsonHeader, body, signal })
}

export const storage = {
  get(k) {
    return localStorage.getItem(k)
  },
  set(k, v) {
    localStorage.setItem(k, v)
  },
  remove(k) {
    localStorage.removeItem(k)
  },
}

export const FETCH_STATUS_IDLE = 'idle'
export const FETCH_STATUS_REQUEST = 'request'
export const FETCH_STATUS_SUCCESS = 'success'
export const FETCH_STATUS_FAILURE = 'failure'

export const ERROR_ABORT_CONTROLLER = 'AbortError'

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const findMatchPermissionsAtLeastSuperUser = (requirePermissions = [], permissions = []) => {
  let matchPermissions = permissions
  if (requirePermissions.length !== 0) {
    matchPermissions = permissions.filter(permission => ['superuser', ...requirePermissions].includes(permission))
  }
  return matchPermissions
}

export const isServer = () => typeof window === 'undefined'

export const cryptoHash = value => {
  return crypto.pbkdf2Sync(value, HASH_SALT, 1000, 64, `sha512`).toString(`hex`)
}

export const intersectTwoArray = (array1, array2) => {
  const setA = new Set(array1)
  const setB = new Set(array2)
  const intersect = new Set([...setA].filter(ele => !setB.has(ele)))
  return Array.from(intersect)
}

export const chunkArray = (arrayData, chunkSize = 1) => {
  const arrayLength = arrayData.length
  const resultArrayChunk = []

  for (let index = 0; index < arrayLength; index += chunkSize) {
    const chunkData = arrayData.slice(index, index + chunkSize)
    resultArrayChunk.push(chunkData)
  }
  return resultArrayChunk
}

export const ERROR_MESSAGE_STOP_ROUTER_CHANGE = 'Need to throw this error to stop router change. Please ignore this error.'
