export const API_LIST_CAMERA = '/api/camera'
export const API_CAMERA_WEBHOOK = '/api/camera/webhook'
export const API_CREATE_CAMERA_WEBHOOK = '/api/camera/:id/webhook'

export const API_WEBHOOK_METHOD = '/api/webhooks/methods'
export const API_WEBHOOK_EVENT = '/api/webhooks/events'

export const API_LIST_VEHICLE_TYPE = '/api/visitor/vehicle/type'
export const API_LIST_PURPOSE = '/api/visitor/purpose'
export const API_LIST_TODAY_VISITOR = '/api/visitor/camera'
export const API_LIST_PLATE_ORIGIN = '/api/vehicle/plate-origins'
export const API_LIST_PLATE_COLOR = '/api/vehicle/plate-colors'
export const API_UPDATE_VISITOR = '/api/visitor'

export const API_AVG_TIMESPENT = '/api/traffic/averagetimespent'
export const API_GREENGAS = '/api/traffic/greengas'
export const API_MAX_GREENGAS = '/api/traffic/greengas/max'
export const API_LATEST_VEHICLE = '/api/traffic/vehicle'
export const API_VEHICLE_COUNT = '/api/traffic/vehiclecount'
export const API_VEHICLE_COUNT_LASTHOUR = '/api/vehicle/count/lastHour'
export const API_MAX_VEHICLE_COUNT = '/api/traffic/vehiclecount/max'
export const API_VEHICLE_MEMBER = '/api/vehicle/count/member'
export const API_VEHICLE_PURPOSE = '/api/vehicle/count/purpose'
export const API_TRAFFIC_DESTINATION = '/api/traffic/vehiclecount/destination'
export const API_TRAFFIC_PURPOSE = '/api/traffic/vehiclecount/purpose'
export const API_TRAFFIC_TYPE = '/api/traffic/vehiclecount/type'
export const API_TRAFFIC_OFFPEAK = '/api/traffic/vehiclecount/offpeak'
export const API_TRAFFIC_ONPEAK = '/api/traffic/vehiclecount/onpeak'
export const API_TRAFFIC_PERHOUR = '/api/traffic/vehiclecount/perhour'
export const API_TRAFFIC_DESTINATION_RANKING = '/api/vehicle/member/company'

export const API_LIST_NOTIFICATION = '/api/notification'
export const API_AUTH = '/api/auth/session'
export const API_AUTH_UPDATE_PROFILE = '/api/auth/profile'

export const API_USER_MANAGEMENT = '/api/user-management'
export const API_USER_MANAGEMENT_ROLE = '/api/user-management/roles'
export const API_REGISTER_USER_MANAGEMENT = '/api/user-management/register'

export const API_LIST_ZONE = '/api/incidence/zone'
export const API_LIST_INCIDENCE_YEAR = '/api/incidence/year'
export const API_LIST_INCIDENCE_YEAR_COUNT = '/api/incidence/year/count'
export const API_LIST_INCIDENCE_CASE = '/api/incidence'

export const API_LIST_VISITOR_HISTORY = '/api/visitor/history'

export const API_DESTINATION = '/api/destinations'
export const API_DESTINATION_LIST_MEMBER = '/api/destinations/:id/members'
export const API_DESTINATION_SEARCH = '/api/destinations/search'

export const API_MEMBER = '/api/members'
export const API_LIST_MEMBER = '/api/members/search'

export const API_SYSTEM_STATUS = '/api/system-status'
